// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

section.bg-light,
section.bg-dark {
	padding: 0;
	background-color: transparent !important;


	h1, h2, h3, h4, h5, h6 {
		color: $gold-dark;
	}

	.wysiwyg p {
		margin: 0;
	}

	> .container .container-holder {
		.column {
			justify-content: center;
			align-items: center;
			margin-bottom: 0 !important;

			&.one {
				@include media-breakpoint-down(sm) {
					padding: 15px !important;
					justify-content: flex-start;
					align-items: flex-start;
				}

				strong {
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}

				img {
					position: relative;
					left: -15px;
				}
			}

			&.two {
				img {
					position: relative;
					@include media-breakpoint-up(md) {
						right: -15px;
					}
				}
			}
		}
	}
}

section.bg-dark {
	background-color: transparent !important;

	> [class*='container'] {
		background: $gold !important;
	}
}

section.bg-light {
	> [class*='container'] {
		background: $gray !important;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 12vh 0;

	.container-one-column {
		.container-holder {
			max-width: 620px;
			text-align: left;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 12vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.wysiwyg {
		p {
			max-width: 549px;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 12vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 12vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 12vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 12vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

@media(min-width: 768px) {
	&.home {
		> .title-section {
			.title {
				max-width: 332px;
			}
		}
	}

	&.default {
		> .title-section {
			max-width: calc(100% - 345px);
			margin-left: auto;
		}
	}
}

.title-section {
	margin-top: 250px;
	@include media-breakpoint-down(sm) {
		margin-top: 100px;
	}

	.logo {
		max-width: 243px;
		margin-bottom: 100px;
		@include media-breakpoint-down(sm) {
			margin-left: -30px;
		}
	}
}

section > .container {
	//margin-left: -15px;
}

.reserveren-sectie {

}

//.owl-item {
//	max-width: 800px;
//}



// footer
.footer {
	.footer-socket {
		padding-bottom: 35px;
		padding-top: 55px;

		.container {
			margin-left: auto;
			margin-right: 0;

			ul.list {
				justify-content: flex-end;

				li.list-item {
					margin-right: 15px;

					a.link {
						color: $brown;
						font-size: $font-size-base * 0.75;
					}
				}
			}
		}
	}
}

.footer-sticky-content {
	position: fixed;
	left: 0;
	top: 0;
	height: 50%;
	background: transparent;
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 345px;
	padding: 75px 15px 0;
	@include media-breakpoint-down(sm) {
		position: initial;
		max-width: 100%;
		padding-top: 25px;
	}

	.container {
		height: 100%;

		.container-holder {
			height: 100%;

			.column {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	&.sticky-logo {
		@media(max-width: 767px) {
			display: none;
		}
	}

	&.sticky-text,
	.contact-info-section {
		top: 50%;
		z-index: 9000;


		> [class*='container-'] {
			.container-holder {
				align-items: flex-end;
			}
		}
	}
}

.sticky-text,
.contact-info-section {
	top: 50%;
	z-index: 9000;

	.footer-text {
		margin-bottom: 15px;

		p {
			margin: 0;
			font-size: $font-size-base;
			line-height: 17px;
		}

		&.text-rating {
			display: flex;
			align-items: center;
			margin-top: 15px;

			p {
				font-size: 9px;
				color: #000;
				position: relative;
				margin-left: 55px;

				&:before {
					content: url('/images/gaultmillau.svg');
					margin-right: 5px;
				}

				strong {
					font-weight: 900;
				}

				&:after {
					content: url('/images/hoedjes.svg');
					position: absolute;
					right: 0;
					top: -2px;
					width: 33px;
				}
			}

			&:before {
				content: url('/images/flower.svg');
				position: absolute;
			}
		}
	}

	> [class*='container-'] {
		.container-holder {
			align-items: flex-end;
		}
	}
}

.footer {
	a[href*='tel:'],
	a[href*='mailto:'] {
		font-size: 0;
		margin-right: 15px;
		margin-top: 10px;
		display: inline-block;

		i {
			font-size: 18px;
		}
	}
	.logo-les-patrons-cuisiniers {
		position: absolute;
		left: 175px;
		bottom: 20px;
		img {
			height: 25px;
		}
	}
}

// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 365px;
	min-height: 365px;
	max-height: 720px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 365px;
	min-height: 365px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

&.menu-page {
	section {
		> .container {
			margin-left: auto;
		}
	}

	.menu-section {
		> .container {
			background: $gray;

			.column.one {
				padding-top: 160px;
				padding-left: 90px;
				padding-bottom: 160px;
			}

			.column.two {
				padding-right: 0;
			}
		}
	}

	.page-block {
		&.wysiwyg {
			margin-bottom: 55px; 

			p { 

				strong {
					color: $gold-dark;
				}
			}
		}
	}
}

.nopadding-column .column {
	padding: 0 !important;
}

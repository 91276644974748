// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: auto;
	right: 0;
	width: calc(100% - 345px);
	background: $white;
	transition: 0.5s;

	@include media-breakpoint-down(sm) {
		width: 100% !important;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;
				justify-content: flex-end;
				padding: 15px;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;

		.navbar-toggler {
			padding: 0;
			border: none;
			color: $gold-darkest;
			font-size: 14px;
			font-weight: 400;
			transition: 0.5s;

			i {
				width: 30px;
				font-size: 22px;
				line-height: 40px;
				font-weight: 400;
				text-align: center;
			}

			.navbar-toggler-label {
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;

			.navbar-nav {
				margin-top: 15px;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $gold;
						font-size: 14px;
						font-weight: 400;

						&:hover {
							color: $brown;
						}
					}

					&.active {
						>.nav-link {
							color: $brown;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 14px;
							font-weight: 400;

							.nav-link {
								color: $gold;

								&:hover {
									color: $brown;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $brown;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				margin-right: 15px;

				>.nav-item {
					margin-left: 5px;
					font-size: 14px;
					font-weight: 400;

					>.nav-link {
						color: $gold;

						&:hover {
							color: $brown;
						}
					}

					&.active {
						>.nav-link {
							color: $brown;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						border-color: $gold;
						box-shadow: none;
						padding: 0.5rem;
						border-radius: 4px;

						&::before {
							border-bottom-color: $gold;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $gold;

								&:hover {
									color: $brown;
								}
							}

							&.active {
								.nav-link {
									color: $brown;
								}
							}
						}
					}
				}
			}
		}
	}

	// quick-buttons
	div.quick-buttons {
		ul {
			li {
				+li {
					margin-left: 10px;
				}

				a {
					@extend .btn;
					@extend .btn-primary;
					min-height: 34px !important;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		.navbar-nav {
			.nav-item {
				.nav-link {
					border: none;
					box-shadow: none !important;
					color: $gold-darkest;
					font-size: 14px;
				}

				.dropdown-menu {
					border: 1px solid $gold-dark;
					border-radius: .25rem;
					overflow: hidden;

					.dropdown-item {
						color: $gold-darkest;
						font-size: 14px;
					}
				}
			}
		}
	}
}

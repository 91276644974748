// btn
.btn {
	box-shadow: none;
	font-size: $font-size-base * 0.875;
	letter-spacing: 0.7px;
	padding: 5px 16px;
	border-radius: $border-radius;
	display: inline-flex;
	align-items: center;
	position: relative;
	min-height: 40px;
	font-weight: 400;
	line-height: 0;

	// btn-primary
	&.btn-primary {
		background: linear-gradient(114deg, $primary 0%, $secondary 100%);
		color: #fff;

		&:hover {
			background: linear-gradient(114deg, $secondary 0%, $primary 100%);
			color: #fff;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}

	&.btn-icon {
		padding-right: 55px;

		&:after {
			content: url('/images/r.svg');
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

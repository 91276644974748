body {
	font-weight: 300;
}

h1, h2, h3, h4, h5 {
	text-transform: uppercase;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
}

@media(min-width: 768px) {
	main.main {
		max-width: calc(100% - 345px);
		margin-left: auto;
	}
	.eyecatcher > .container {
		max-width: calc(100% - 345px);
		margin-right: 0;

		.column {
			padding-right: 0 !important;
		}
	}
}

&.default {
	font-size: 1.063rem;
	font-weight: 300;
}

table {
	margin-bottom: 35px;
}

table,
td {
	border: none;
	font-weight: 400;
}

td {
	display: inline-block;
	margin-bottom: 30px;
}

.desktop-hidden { 
 
		display: none;

}

@media(max-width: 767px) {
	.mobile-hidden {
		display: none;
	}
	.eyecatcher {
		margin-right: -40px;
	}
	section > .container,
	section > .container .container-holder,
	section > .container .container-holder .column {
		padding: 0 !important;
		margin: 0 !important;
	}
	&.default {
		max-width: calc(100%);
		padding-right: 40px;
		padding-left: 40px;
		margin-left: auto;
	}
	&.reserveren-page {
		padding-right: 0;
	}
	&.menu-page {
		padding-right: 0;

		section:not(.title-section) > .container .container-holder {
			padding: 40px 40px !important;
			padding-bottom: 0 !important;
		}

		.menu-section {
			order: 1;

			> .container .column.two .page-block {
				&:first-of-type {
					margin-bottom: 0;

					img {
						margin-left: -40px;
						margin-right: -40px;
						width: calc(100vw - 40px);
						max-width: calc(100vw - 40px);
					}
				}

				&:last-of-type {
					order: -1;
				}
			}
		}

		.main {
			display: flex;
			flex-flow: row wrap;

			section:last-of-type {
				> .container .container-holder {
					padding: 0 !important;
				}
			}
		}
	}
}
